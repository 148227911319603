import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
    state: {
        show_product:false,
        login_msg:'',
        productid_popup:1068,
        update_cart_list:false,
        addAddress:false,
        phone:'',
        email:'',
        storeName: "yarnistaqa",
        theme_id: 2,
        unit: "",
        UnitPrice: "",
        unitAR: "",
        fsm_token: "",
        cartCounter: 0,
        tax: null,
        rate:1,
        //OrgilnalNameAr: "",
        //OrgilnalNameEn: "",
        logo: "",
        logo_footer: "",
        langchange: false,
        langchange_check: false,
        langchange1: false,
        notificationUpdate: false,
        userTokenState: false,
        country_code:'',
        country_flag:'',
        country_length:0,
        auth: 0,//for login phone or email
        master_code:"",//for login code
        sms_type:"",//for login phone sms type firebase or normal method
        //base_api_url: "http://192.168.1.16:8000/api/"
        base_api_url: "https://etrolley.net/api/",
        title_ar:"",
        title_en:"",
        description_ar:"",
        description_en:"",
        keyword_ar:"",
        keyword_en:"",
        last_news:0,
            apperance_slider: 1,
            apperance_sections: 1,
            apperance_latest_products: 1,
            apperance_best_saller: 1,
            apperance_fetured_products: 1,
            apperance_offers: 1,
            apperance_brands: 1,
            apperance_map: 1,
            apperance_map_address: 1,
            apperance_lang: 'all',
            apperance_basic_lang: 'ar',
            topbar:'topbar_light',
            navbar:'navbar_light',
            user_name:'',
            loading:false,
            loading_home:false,
            img_login:"/theme8/images/login_img.jpg",
            


    },
    getters: {},
    mutations: {},
    actions: {},
    modules: {}
})